import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { ReturnService } from '@features/returns/services/returns.service';
import { MethodsCategory } from '@core/constants/method-category';
import { BaseComponent } from '@shared/components/base.component';
import { ReturnsConfigService } from '@core/services/returns-config.service';
import { HostedReturnsConfig } from '@core/models/returns-config.interface';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { Store } from '@ngrx/store';
import { getAuthResponse } from '@store/selectors/auth.selector';
import { Auth } from '@core/models/auth';
import { ReturnRequest } from '@core/models/return-request';

@Component({
  selector: 'app-status-loading',
  templateUrl: './status-loading.component.html',
  styleUrls: ['./status-loading.component.scss'],
})
export class StatusLoadingComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  dataSubscription!: Subscription;
  rmaId = '';
  rmaIds: string[] = [];
  RETURN_STATES = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
  };

  // values can be LOADING, SUCCESS, ERROR
  state = this.RETURN_STATES.LOADING;

  hostedReturnsConfig!: HostedReturnsConfig;
  requestDetailsSubscription!: Subscription;
  methodsType = MethodsCategory;
  intervalId!: ReturnType<typeof setInterval>;
  urlParamsLink = '';
  authResponse!: Auth | null;
  fallbackToOldFetchReturnRequestLogic = true;
  isReturnRequestStreamFailed = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private returnService: ReturnService,
    private configService: ReturnsConfigService,
    private store: Store
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.dataSubscription = combineLatest([
      this.activatedRoute.data,
      this.activatedRoute.paramMap,
      this.activatedRoute.queryParamMap,
      this.store.select(getAuthResponse),
    ]).subscribe(([data, params, queryParam, authResponse]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.hostedReturnsConfig = data['configResp'];
      this.rmaId = params.get('rmaId') ? params.get('rmaId') + '' : '';
      const rmaIds = queryParam.get('rId') || '';
      this.rmaIds = rmaIds.split(',').filter(value => value);
      this.authResponse = authResponse;
      this.init();
    });
  }

  init() {
    this.showState(this.RETURN_STATES.LOADING);
    const urlEntities = this.getTenantAndBrandExternalId();
    const orderId = encodeURIComponent(btoa(`${urlEntities?.orderExternalId}`));
    this.urlParamsLink = `/${urlEntities?.tenantId}/${urlEntities?.brandExternalId}/${orderId}`;

    const multiSkuEnable =
      this.hostedReturnsConfig.componentVisibility.multiSku?.enable;

    const isEmptyItemList = !NullEmptyChecker.isNonEmptyArray(
      this.authResponse?.itemList
    );

    if (multiSkuEnable && !isEmptyItemList) {
      if (NullEmptyChecker.isNonEmptyArray(this.rmaIds)) {
        this.fetchBulkRequestDetailsByRmaId(this.rmaIds);
      } else {
        this.redirectToSummaryAfterSomeTime('bulk');
      }
    } else {
      if (this.rmaId === '') {
        this.redirectToSummaryAfterSomeTime('error');
      }
      this.fetchReturnRequestInfo(true);
    }
  }

  processRequestDetails(respData: ReturnRequest, triggerInterval?: boolean) {
    const returnRequestDetails = respData;
    const returnMethod = respData.returnMethod;

    if (returnRequestDetails?.status === 'FAILED') {
      this.state = this.RETURN_STATES.ERROR;
      if (
        this.intervalId &&
        this.fallbackToOldFetchReturnRequestLogic &&
        this.isReturnRequestStreamFailed
      )
        clearInterval(this.intervalId);
      this.clearSubscription();
      this.redirectToSummaryAfterSomeTime('error');
      return;
    }

    // show success icon in case method type is either keep item or return to store
    if (
      returnMethod.type === this.methodsType.KEEP_ITEM ||
      returnMethod.type === this.methodsType.RETURN_TO_STORE
    ) {
      this.state = this.RETURN_STATES.SUCCESS;
      this.redirectToSummaryAfterSomeTime('success');
      return;
    }

    let needToClearInterval = false;
    if (returnRequestDetails?.returnOrder) {
      const { labelLink, scanCodeLink } = returnRequestDetails.returnOrder;
      if (
        (returnMethod.type === this.methodsType.SHIP_IT_BACK ||
          returnMethod.type === this.methodsType.HOME_PICKUP) &&
        labelLink
      ) {
        needToClearInterval = true;
      } else if (this.isQRCodeMethodType(returnMethod.type) && scanCodeLink) {
        needToClearInterval = true;
      }

      if (needToClearInterval) {
        this.state = this.RETURN_STATES.SUCCESS;
        if (
          this.intervalId &&
          this.fallbackToOldFetchReturnRequestLogic &&
          this.isReturnRequestStreamFailed
        ) {
          clearInterval(this.intervalId);
        }
        this.clearSubscription();
        this.redirectToSummaryAfterSomeTime('success');
      }
    }

    if (
      triggerInterval &&
      !needToClearInterval &&
      this.fallbackToOldFetchReturnRequestLogic &&
      this.isReturnRequestStreamFailed
    ) {
      this.callInterval(respData);
    }
  }

  fetchReturnRequestInfo(triggerInterval?: boolean) {
    if (
      this.fallbackToOldFetchReturnRequestLogic &&
      this.isReturnRequestStreamFailed
    ) {
      this.requestDetailsSubscription.unsubscribe();
      this.fetchRequestDetailsFromApi(triggerInterval);
      console.log('calling api');
    } else {
      this.fetchRequestDetailsFromStream(triggerInterval);
      console.log('calling stream');
    }
  }

  fetchRequestDetailsFromApi(triggerInterval?: boolean) {
    this.requestDetailsSubscription = this.returnService
      .getRequestDetailsById(this.rmaId)
      .subscribe({
        next: response => {
          if (!NullEmptyChecker.isNull(response)) {
            this.processRequestDetails(
              response as ReturnRequest,
              triggerInterval
            );
          }
        },
        error: err => {
          this.state = 'ERROR';
          this.redirectToSummaryAfterSomeTime('error');
          console.error('error while fetching request=>', err);
        },
      });
  }

  fetchRequestDetailsFromStream(triggerInterval?: boolean) {
    this.requestDetailsSubscription = this.returnService
      .getReturnRequestInfoFromStream(this.rmaId)
      .subscribe({
        next: (response: any) => {
          if (!NullEmptyChecker.isNull(response)) {
            response = NullEmptyChecker.isNonEmptyArray(response)
              ? response[0]
              : response;
            this.processRequestDetails(
              response as ReturnRequest,
              triggerInterval
            );
          }
        },
        error: err => {
          console.error('error while fetching request from stream=>', err);
          this.isReturnRequestStreamFailed = true;
          this.fetchReturnRequestInfo(triggerInterval);
        },
      });
  }

  fetchBulkRequestDetailsByRmaId(rmaIds: string[]) {
    this.requestDetailsSubscription = this.returnService
      .getBulkRequestDetailsById(rmaIds)
      .subscribe({
        next: () => {
          this.state = this.RETURN_STATES.SUCCESS;
          this.redirectToSummaryAfterSomeTime('bulk');
        },
        error: err => {
          this.state = this.RETURN_STATES.ERROR;
          this.redirectToSummaryAfterSomeTime('error');
          console.error('error while fetching request=>', err);
        },
      });
  }

  isQRCodeMethodType(returnMethod?: string): boolean {
    if (
      returnMethod &&
      [
        this.methodsType.HOME_PICKUP_QR_CODE.toString(),
        this.methodsType.HOME_PICKUP_QR_CODE_BOX.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE_BOX.toString(),
      ].includes(returnMethod)
    ) {
      return true;
    }

    return false;
  }

  callInterval(resp: any) {
    const { returnOrder, returnMethod } = resp;
    let isPollingNeeded = false;
    if (
      returnMethod.type === this.methodsType.SHIP_IT_BACK ||
      returnMethod.type === this.methodsType.HOME_PICKUP
    ) {
      if (!returnOrder) {
        isPollingNeeded = true;
      } else if (returnOrder && !returnOrder.labelLink) {
        isPollingNeeded = true;
      }
    } else if (this.isQRCodeMethodType(returnMethod.type)) {
      if (!returnOrder) {
        isPollingNeeded = true;
      } else if (returnOrder && !returnOrder.scanCodeLink) {
        isPollingNeeded = true;
      }
    }

    if (isPollingNeeded) {
      this.state = this.RETURN_STATES.LOADING;
      this.intervalId = setInterval(() => {
        this.fetchReturnRequestInfo(false);
      }, 5000);
    }
  }

  showState(stateName: string) {
    this.state = stateName;
  }

  clearSubscription() {
    if (this.requestDetailsSubscription) {
      this.requestDetailsSubscription.unsubscribe();
      // this.returnService.closeRRSseConnection();
    }
  }

  redirectToSummaryAfterSomeTime(pageType: string) {
    let url = '';
    let queryParams = {};
    if (pageType === 'bulk') {
      url = `/${this.urlParamsLink}/main/return-summary`;
      queryParams = { rId: this.rmaIds.join(',') };
    } else if (pageType === 'error') {
      url = `/${this.urlParamsLink}/main/request-placed/error/${this.rmaId}`;
    } else {
      url = `/${this.urlParamsLink}/main/request-placed/success/${this.rmaId}`;
    }
    setTimeout(() => {
      this.router.navigate([url], { queryParams });
      this.returnService.closeRRSseConnection();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.requestDetailsSubscription) {
      this.requestDetailsSubscription;
    }
    if (this.intervalId) clearInterval(this.intervalId);
    this.clearSubscription();
  }
}

