import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, combineLatest, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { MethodsCategory } from '@constants/method-category';
import { SidenavComponent } from '@core/components/sidenav/sidenav.component';
import { Auth } from '@core/models/auth';
import { RefundMode } from '@core/models/refund-mode';
import { ReturnMethod } from '@core/models/return-method';
import {
  ExchangeMetadataAttribute,
  ReturnRequest,
} from '@core/models/return-request';
import { ReturnReason } from '@core/models/return-resons';
import {
  AdConfig,
  HostedReturnsConfig,
  Recommendations,
} from '@core/models/returns-config.interface';
import { ReturnService } from '@features/returns/services/returns.service';
import { NearbyStoresService } from '@services/nearby-store-locations.service';
import { ReturnsConfigService } from '@services/returns-config.service';
import { BaseComponent } from '@shared/components/base.component';
import {
  getAuthResponse,
  getOrderIdAndUser,
} from '@store/selectors/auth.selector';
import * as moment from 'moment-timezone';
import { Item } from '@core/models/item';
import { NullEmptyChecker } from '@deliverysolutions/utils';
import { ReturnWindowPickupInstType } from '@core/models/return-smart-windows';
import { DSPService, DspType } from '@services/dsp.service';
import { ModalComponent } from '@shared/components/modal/modal.component';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperDirective } from '@shared/directive';

@Component({
  selector: 'app-request-placed',
  templateUrl: './return-request-placed.component.html',
  styleUrls: ['./return-request-placed.component.scss'],
})
export class ReturnRequestPlacedComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  returnInstructions!: string;
  returnReason!: ReturnReason;
  returnMethod!: ReturnMethod;
  modeOfRefund!: RefundMode | undefined;
  recommendations!: Recommendations[] | undefined;
  hostedReturnsConfig!: HostedReturnsConfig;
  rmaId = '';
  methodsType = MethodsCategory;
  intervalId!: ReturnType<typeof setInterval>;
  requestDetailsSubscription!: Subscription;
  returnRequestDetails!: ReturnRequest;
  providerInfo?: DspType;
  showPrintLabelButton = false;
  selectedReturnSmartWindow?: ReturnWindowPickupInstType;
  authResponseSubscription!: Subscription;
  showReturnAnotherItemLink = false;
  eligibleMethods!: ReturnMethod[];
  authResponse!: Auth | null;
  scanCodeLinks: string[] = [];
  deliveryAddress: any;

  dropOffLocation: any;

  pageStatus = '';
  showSidenav = false;
  dataSubscription!: Subscription;
  @ViewChild(SidenavComponent) sidenavInstance!: SidenavComponent;
  public primaryAds: AdConfig | undefined;
  public secondaryAdsOne: AdConfig | undefined;
  public secondaryAdsTwo: AdConfig | undefined;
  public hiddenFields: { [key: string]: boolean } = {};
  placeholderLoaderArr = [1, 2, 3];
  placeHolderLoader = false;
  urlEntities: any;
  details!: any;
  detailsSubscription!: Subscription;
  recommendationsSubscription!: Subscription;
  pageParam: any;
  nearestStorePlaceHolderLoader = false;
  returnInstructionPlaceHolderLoader = false;
  eligibleMethod!: ReturnMethod;
  addressDetails = '';
  pickupWindow: any = {};
  feeConfig!: { showFee: boolean; fee: any };
  timeStr = '';
  itemOb!: Item;
  errorMsgCode = '';
  inventoryMetaData: ExchangeMetadataAttribute[] = [];

  @ViewChild(ModalComponent) public modalComponent!: ModalComponent;
  swiperDirective!: Swiper;
  swiperConfig: SwiperOptions = {
    enabled: true,
    pagination: {
      el: '.swiper-pagination',
      enabled: true,
      type: 'custom',
      renderCustom(swiper, current, total) {
        return current + ' of ' + total;
      },
    },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
  };
  swiperPageIndex = 1;
  @ViewChild(SwiperDirective)
  scanCodeSwiperDirective!: SwiperDirective;
  scanCodeSwiperConfig: SwiperOptions = {
    enabled: true, // enable this when multiple scanCodeLinks feature is implemented
    pagination: {
      el: '.swiper-pagination',
      enabled: true,
      type: 'custom',
      renderCustom(swiper, current, total) {
        return current + ' of ' + total;
      },
    },
    autoHeight: true,
    allowTouchMove: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
    loop: true,
    rewind: true,
    spaceBetween: 30,
  };
  isReturnRequestStreamFailed = false;
  fallbackToOldFetchReturnRequestLogic = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private returnService: ReturnService,
    private store: Store,
    public configService: ReturnsConfigService,
    public dspService: DSPService,
    private nearbyStoreService: NearbyStoresService,
    private modalService: NgbModal
  ) {
    super({ hostedReturnService: configService });
  }

  ngOnInit(): void {
    this.dataSubscription = combineLatest([
      this.route.data,
      this.route.paramMap,
      this.route.queryParamMap,
    ]).subscribe(([data, params, queryParam]) => {
      if (data['configResp'] === undefined) {
        this.router.navigate(['/service-unavailable'], {
          skipLocationChange: true,
        });
        return;
      }
      this.hostedReturnsConfig = data['configResp'];
      const hiddenFields = this.configService.setHiddenFields(
        this.hostedReturnsConfig
      );
      this.hiddenFields = { ...this.hiddenFields, ...hiddenFields };
      this.pageParam = params.get('status');
      this.rmaId = params.get('rmaId') || '';
      this.errorMsgCode = queryParam.get('code') || '';
      this.init();
    });
  }

  init() {
    this.detailsSubscription = this.store
      .select(getOrderIdAndUser)
      .subscribe(data => {
        if (data) {
          this.details = data;
        }
      });
    this.urlEntities = this.getTenantAndBrandExternalId();
    this.setUpAdsConfig(this.hostedReturnsConfig);
    this.setupRecommendations(this.hostedReturnsConfig);
    this.authResponseSubscription = this.store
      .select(getAuthResponse)
      .pipe()
      .subscribe(resp => {
        this.authResponse = resp;
        const { deliveryAddress } = this.authResponse!.invoiceInfo;
        this.deliveryAddress = deliveryAddress;
        this.showReturnAnotherItemLink = resp.itemList?.length > 0;
      });

    // if rmaId is not present,
    // redirect to the return-request-status error page
    if (this.rmaId === '') {
      this.pageStatus = 'error';
      return;
    }
    this.fetchReturnRequestInfo();
  }

  private setUpAdsConfig(config: HostedReturnsConfig) {
    this.secondaryAdsOne = config.components.ads.find(ads => {
      return ads.active && ads.type === 'secondaryOne';
    });

    this.secondaryAdsTwo = config.components.ads.find(ads => {
      return ads.active && ads.type === 'secondaryTwo';
    });

    this.primaryAds = config.components.ads.find(ads => {
      return ads.active && ads.type === 'primary';
    });
  }

  public setupRecommendations(hostedReturnsConfig: HostedReturnsConfig) {
    const recommendations = hostedReturnsConfig.components?.recommendations;
    const orderId = this.details ? this.details.orderId : '';

    if (recommendations && recommendations.active) {
      if (recommendations.url) {
        this.recommendationsSubscription = this.configService
          .fetchExternalRecommendations(recommendations.url, orderId)
          .subscribe((result: any) => {
            if (Array.isArray(result) && result.length) {
              this.recommendations = result;
            }
          });
      } else {
        this.recommendations =
          hostedReturnsConfig.components?.recommendations?.jsonFileId;
      }
    }
  }

  getReturnMethods() {
    this.returnInstructionPlaceHolderLoader = true;
    const returnMethodRequest: any = {
      itemList: this.returnRequestDetails.itemList,
      returnReason: {
        code: this.returnReason.code,
        name: this.returnReason.name,
      },
      invoice: this.authResponse?.invoiceInfo,
    };
    this.returnService
      .getReturnMethods(returnMethodRequest)
      .subscribe(methods => {
        this.returnInstructionPlaceHolderLoader = false;
        this.eligibleMethods = methods;
        const method = methods.find(
          (method: ReturnMethod) => method.code === this.returnMethod.code
        );
        this.returnInstructions = method?.instructions;
        this.eligibleMethod = method;
        if (this.returnRequestDetails.hasOwnProperty('fee')) {
          this.feeConfig = {
            showFee: true,
            fee: this.returnRequestDetails.fee,
          };
        }
      });
  }

  formatAddress(address: any) {
    return `
			${address?.street},
			${address?.street2 ? address.street2 + ',' : ''}
			${address?.city},
			${address?.state},
			${address?.zipcode},
			${address?.country}
		`;
  }

  fetchNearestStore() {
    this.nearestStorePlaceHolderLoader = true;
    const ob = {
      address: this.deliveryAddress,
      page: 1,
      limit: 1,
    };
    this.nearbyStoreService.fetchNearbyStores(ob).subscribe({
      next: (respData: any) => {
        this.nearestStorePlaceHolderLoader = false;
        if (respData.totalCount > 0) {
          const nearbyStore = respData.storeList[0];
          let addressStr = '';
          const { address, contact, distance, unit } = nearbyStore;
          if (address) {
            addressStr = this.formatAddress(address);
          }
          this.dropOffLocation = {
            address: addressStr,
            phone: contact.phone,
            distance: `${distance} ${unit} away`,
            time: '',
            latitude: address.latitude,
            longitude: address.longitude,
          };
        }
      },
      error: err => {
        this.nearestStorePlaceHolderLoader = false;
        console.error('error while fetching nearest store=>', err);
      },
    });
  }

  processRequestDetails(resp: ReturnRequest) {
    this.placeHolderLoader = false;

    this.returnRequestDetails = resp;
    this.providerInfo = this.returnRequestDetails?.returnOrder?.providerInfo;
    this.selectedReturnSmartWindow = {
      ...(this.returnRequestDetails.pickupTime && {
        pickupTime: this.returnRequestDetails.pickupTime,
      }),
      ...(this.returnRequestDetails.dropoffTime && {
        dropoffTime: this.returnRequestDetails.dropoffTime,
      }),
      ...(this.returnRequestDetails.pickupInstructions && {
        pickupInstructions: this.returnRequestDetails.pickupInstructions,
      }),
      ...(this.returnRequestDetails.timeZone && {
        timeZone: this.returnRequestDetails.timeZone,
        tz: this.returnRequestDetails.timeZone,
      }),
    };
    if (NullEmptyChecker.isNonEmptyArray(resp.itemList)) {
      const item = resp?.itemList?.[0];
      this.itemOb = JSON.parse(JSON.stringify(item));
      this.itemOb.itemQuantity = item?.quantity;
      delete this.itemOb.returnReason;
      delete this.itemOb.refundMode;
    }
    const { customerAddress } = this.returnRequestDetails;
    this.addressDetails = this.formatAddress(customerAddress);

    const itemList = resp?.itemList;
    if (itemList && NullEmptyChecker.isNonEmptyArray(itemList)) {
      const [item] = itemList;
      this.returnReason = item?.returnReason || resp.returnReason;
      this.returnMethod = (item?.returnMethod ||
        resp.returnMethod) as ReturnMethod;
      this.modeOfRefund = (item?.refundMode || resp.refundMode) as RefundMode;
    } else {
      this.returnReason = resp.returnReason;
      this.returnMethod = resp.returnMethod;
      this.modeOfRefund = resp.refundMode;
    }
    if (NullEmptyChecker.isNonEmptyArray(resp.exchangeMetadata)) {
      this.inventoryMetaData = resp?.exchangeMetadata?.flatMap(
        (metaData: { attributes: any }) => metaData.attributes
      ) as ExchangeMetadataAttribute[];
    }

    //setting up nearest store section
    if (
      this.deliveryAddress &&
      this.returnMethod.type === this.methodsType.RETURN_TO_STORE
    ) {
      this.fetchNearestStore();
    }

    if (this.returnRequestDetails?.status === 'FAILED') {
      this.pageParam = 'error';
      this.pageStatus = 'error';
      if (this.intervalId) clearInterval(this.intervalId);
      this.clearSubscription();
      return;
    }

    if (this.returnRequestDetails?.returnOrder) {
      const {
        labelLink,
        scanCodeLink,
        estimatedPickupTimeStarts,
        estimatedPickupTimeEnds,
        requestedTime,
        shipments,
      } = this.returnRequestDetails.returnOrder;
      let needToClearState = false;
      if (
        this.returnMethod.type === this.methodsType.SHIP_IT_BACK ||
        this.returnMethod.type === this.methodsType.HOME_PICKUP
      ) {
        if (labelLink) {
          this.showPrintLabelButton = true;
          this.pageStatus = 'success';
          needToClearState = true;
        }
      } else if (this.isQRCodeMethodType(this.returnMethod.type)) {
        if (NullEmptyChecker.isNonEmptyArray(shipments)) {
          this.scanCodeLinks = shipments!
            .filter(shipment => shipment.scanCodeLink)
            .map(shipment => shipment.scanCodeLink);
        }

        if (
          !NullEmptyChecker.isNonEmptyArray(this.scanCodeLinks) &&
          scanCodeLink
        ) {
          this.scanCodeLinks = [scanCodeLink];
          this.pageStatus = 'success';
          needToClearState = true;
        }
      }

      if (
        this.returnMethod.type === this.methodsType.HOME_PICKUP ||
        this.returnMethod.type === this.methodsType.HOME_PICKUP_QR_CODE ||
        this.returnMethod.type === this.methodsType.HOME_PICKUP_QR_CODE_BOX
      ) {
        this.pickupWindow = {
          estimatedPickupTimeStarts,
          estimatedPickupTimeEnds,
          requestedTime,
        };
        this.renderPickupWindow();
      }

      if (needToClearState) {
        if (this.intervalId) clearInterval(this.intervalId);
        this.clearSubscription();
      }
    }

    this.pageStatus = 'success';

    if (!this.eligibleMethods || this.eligibleMethods?.length === 0)
      this.getReturnMethods(); // check if eligible methods are already present
  }

  fetchReturnRequestInfo() {
    if (
      this.fallbackToOldFetchReturnRequestLogic &&
      this.isReturnRequestStreamFailed
    ) {
      this.requestDetailsSubscription.unsubscribe();
      this.returnService.closeRRSseConnection();
      this.fetchRequestDetailsFromApi();
    } else {
      this.fetchRequestDetailsFromStream();
    }
  }

  fetchRequestDetailsFromApi() {
    this.requestDetailsSubscription = this.returnService
      .getRequestDetailsById(this.rmaId)
      .subscribe({
        next: response => {
          if (!NullEmptyChecker.isNull(response)) {
            this.processRequestDetails(response as ReturnRequest);
          }
        },
        error: err => {
          this.placeHolderLoader = false;
          this.pageStatus = 'error';
          console.error('error while fetching request=>', err);
        },
      });
  }

  fetchRequestDetailsFromStream() {
    this.placeHolderLoader = true;
    this.requestDetailsSubscription = this.returnService
      .getReturnRequestInfoFromStream(this.rmaId)
      .subscribe({
        next: (response: any) => {
          if (!NullEmptyChecker.isNull(response)) {
            response = NullEmptyChecker.isNonEmptyArray(response)
              ? response[0]
              : response;
            this.processRequestDetails(response as ReturnRequest);
          }
        },
        error: err => {
          console.error('error while fetching request from stream=>', err);
          this.isReturnRequestStreamFailed = true;
          this.fetchReturnRequestInfo();
        },
      });
  }

  callInterval(resp: any) {
    const returnOrder = resp.returnOrder;
    let isPollingNeeded = false;
    if (
      this.returnMethod.type === this.methodsType.SHIP_IT_BACK ||
      this.returnMethod.type === this.methodsType.HOME_PICKUP
    ) {
      if (!returnOrder) {
        isPollingNeeded = true;
      } else if (returnOrder && !returnOrder.labelLink) {
        isPollingNeeded = true;
      }
    } else if (this.isQRCodeMethodType(this.returnMethod.type)) {
      if (!returnOrder) {
        isPollingNeeded = true;
      } else if (returnOrder && !returnOrder?.scanCodeLink) {
        isPollingNeeded = true;
      }
    }

    if (isPollingNeeded) {
      if (this.pageParam !== 'return-order-details')
        this.pageStatus = 'processing';
      this.intervalId = setInterval(() => {
        this.fetchReturnRequestInfo();
      }, 5000);
    }
  }

  clearSubscription() {
    if (this.requestDetailsSubscription) {
      this.requestDetailsSubscription.unsubscribe();
      this.returnService.closeRRSseConnection();
    }
  }

  ngOnDestroy(): void {
    if (this.dataSubscription) this.dataSubscription.unsubscribe();
    if (this.requestDetailsSubscription)
      this.requestDetailsSubscription.unsubscribe();
    if (this.intervalId) clearInterval(this.intervalId);
    if (this.authResponseSubscription)
      this.authResponseSubscription.unsubscribe();
    if (this.detailsSubscription) this.detailsSubscription.unsubscribe();
    if (this.recommendationsSubscription)
      this.recommendationsSubscription.unsubscribe();
  }

  openDropOffLocations() {
    this.showSidenav = true;
  }

  closeDropOffLocations() {
    this.showSidenav = false;
  }

  isQRCodeMethodType(returnMethod?: string): boolean {
    if (
      returnMethod &&
      [
        this.methodsType.HOME_PICKUP_QR_CODE.toString(),
        this.methodsType.HOME_PICKUP_QR_CODE_BOX.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE.toString(),
        this.methodsType.SHIP_IT_BACK_QR_CODE_BOX.toString(),
      ].includes(returnMethod)
    ) {
      return true;
    }

    return false;
  }

  renderPickupWindow() {
    if (!NullEmptyChecker.isNonEmptyArray(Object.keys(this.pickupWindow))) {
      return;
    }
    const {
      estimatedPickupTimeStarts,
      estimatedPickupTimeEnds,
      requestedTime,
    } = this.pickupWindow;
    const epoch =
      estimatedPickupTimeStarts || estimatedPickupTimeEnds || requestedTime;
    if (epoch) {
      const date = this.getDate(epoch);
      if (estimatedPickupTimeStarts && estimatedPickupTimeEnds) {
        const from = this.getTime(estimatedPickupTimeStarts);
        const to = this.getTime(estimatedPickupTimeEnds);
        this.timeStr = `Between ${date} ${from} and ${date} ${to}`;
      } else if (estimatedPickupTimeStarts) {
        const from = this.getTime(estimatedPickupTimeStarts);
        this.timeStr = `From ${date} ${from}`;
      } else if (estimatedPickupTimeEnds) {
        const to = this.getTime(estimatedPickupTimeEnds);
        this.timeStr = `By ${to}`;
      } else if (requestedTime) {
        const from = this.getTime(requestedTime);
        this.timeStr = `From ${date} ${from}`;
      }
    }
  }

  getDate(date: number) {
    const timezone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    return moment.tz(date, timezone).format('MMM DD, YYYY');
  }

  getTime(time: number) {
    const timezone = Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
    return moment.tz(time, timezone).format('h:mm A');
  }

  goToItemlist() {
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    const urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.router.navigate([`/${urlParamsLink}/main/itemlist`]);
  }

  goToReturnDetails() {
    const orderId = encodeURIComponent(
      btoa(`${this.urlEntities?.orderExternalId}`)
    );
    const urlParamsLink = `/${this.urlEntities?.tenantId}/${this.urlEntities?.brandExternalId}/${orderId}`;
    this.router.navigate([
      `/${urlParamsLink}/main/return-details/${this.itemOb?.sku}/${this.urlEntities?.orderExternalId}`,
    ]);
  }

  setSwiperDirective() {
    if (!this.swiperDirective || this.swiperDirective.destroyed) {
      const swiperEl: any = document.getElementById('previewModalSwiper');
      if (!swiperEl) return;
      this.swiperDirective = swiperEl.swiper;
      this.swiperDirective.slideTo(this.swiperPageIndex - 1);
    }

    return this.swiperDirective;
  }

  closeModalComponent() {
    this.modalService.dismissAll();
  }

  openModalComponent(modal: TemplateRef<any>, index: number) {
    this.swiperPageIndex = index + 1;
    this.modalService
      .open(modal, {
        centered: true,
        keyboard: false,
      })
      .shown.subscribe(() => {
        this.setSwiperDirective();
      });
  }

  paginatePreviewModal(direction: 'NEXT' | 'PREV') {
    if (!this.swiperDirective) return;

    switch (direction) {
      case 'NEXT':
        this.swiperDirective.slideNext();
        break;
      case 'PREV':
        this.swiperDirective.slidePrev();
        break;
    }
    this.swiperPageIndex = this.swiperDirective.activeIndex + 1;
  }

  paginateScanCodeLink(direction: 'NEXT' | 'PREV') {
    switch (direction) {
      case 'NEXT':
        this.scanCodeSwiperDirective.next();
        break;
      case 'PREV':
        this.scanCodeSwiperDirective.prev();
        break;
    }
  }
}
